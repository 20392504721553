<template>
  <div class="box-summary-payment">
    <data-summary-payment
      v-if="dataResponse != {}"
      :data-response="dataResponse"
      :products="products"
    />
    <div v-else class="spinner-container">
      <img class="spinner" src="@/assets/loader.gif" alt="" />
    </div>
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/checkout/DataSummaryPayment.vue'
import { mapState } from 'vuex'
export default {
  name: 'PedidoDetalle',
  components: { DataSummaryPayment },
  data() {
    return {
      dataResponse: null,
      products: [],
    }
  },
  computed: {
    ...mapState('', []),
  },
  async mounted() {
    this.$store.state.loaderClover = true
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
    if (location.search) {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/payu/response' + location.search,
      })
        .then(response => {
          this.$store.state.loaderClover = false
          if (response.data.response.carrito_cerrado == 0) {
            if (response.data.response.checkoutAnonimo == 1) {
              this.$router
                .replace({
                  name: 'CheckoutAnonymous',
                  params: {
                    tokenCart: response.data.response.carrito.token,
                    showmodalPaymentDecline: true,
                  },
                })
                .catch(() => {})
            } else {
              this.$router
                .replace({
                  name: 'Checkout',
                  params: { showmodalPaymentDecline: true },
                })
                .catch(() => {})
            }
          } else {
            window.localStorage.removeItem('tokenCart')
            this.dataResponse = response.data.response.pedido
            this.products = response.data.response.pedido.productos
          }
        })
        .catch(() => {
          window.localStorage.removeItem('tokenCart')
          this.$store.state.loaderClover = false
          this.$router.replace({ name: 'Home' }).catch(() => {})
        })
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  methods: {
    goToCuponPago() {
      location.href = this.dataResponse.linkTicketPago
    },
  },
}
</script>
<style scope>
.divider-product {
  height: 1px;
  background: #eaf0fc;
  margin-left: 1rem !important;
  max-width: 90% !important;
}
.sub-title {
  font-size: 18px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.spinner-container .spinner {
  z-index: 100;
  width: 70px;
  height: 70px;
  border: 4px solid #f3f3f3;
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
