<template>
  <div class="container-checkout-email">
    <div class="container-input-group-email">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :class="invalidEmail ? 'invalid' : ''"
          :style="email ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Correo electrónico</label
        >
        <input
          id="email"
          v-model="email"
          :class="invalidEmail ? 'invalid-error' : ''"
          placeholder="Correo electrónico"
          type="email"
          autocomplete="email"
          @blur="validateBlur()"
          @focus="invalidEmail = false"
        />
        <span v-if="invalidEmail" class="inavalida-input">{{
          email ? 'Correo invalido' : 'Campo requerido'
        }}</span>
      </div>
      <div>
        <button
          class="btn-checkout-email bnt-primary"
          :style="!checkTyC || !mayor18 ? 'opacity: .5' : ''"
          :disabled="!checkTyC || !mayor18"
          @click="snedEmail()"
        >
          Continuar
        </button>
      </div>
    </div>
    <div v-if="errorAddToCart" class="mb-4">
      <span class="text-error">{{ msgErrorAddToCart }}</span>
    </div>
    <div class="container-input-checkbox">
      <input
        id="input-checkbox-tel"
        v-model="checkTyC"
        class="input-checkbox"
        type="checkbox"
        checkmark="checkmark"
      />
      <span class="checkmark"></span>
      <label for="input-checkbox-tel"
        >Acepto
        <router-link
          target="_blank"
          :to="{
            name: 'HabeasData',
            params: { politica: 'politica-de-tratamiento-de-datos' },
          }"
          class="router-clover"
          >Términos y condiciones</router-link
        >
        y
        <router-link
          target="_blank"
          :to="{
            name: 'HabeasData',
            params: { politica: 'politica-de-privacidad' },
          }"
          class="router-clover"
        >
          Política de tratamiento de datos.</router-link
        ></label
      >
    </div>
    <div class="container-input-checkbox">
      <input
        id="input-checkbox-tel"
        v-model="mayor18"
        class="input-checkbox"
        type="checkbox"
        checkmark="checkmark"
      />
      <span class="checkmark"></span>
      <label for="input-checkbox-tel"
        >Confirmo que soy mayor de 18 años -
        <span><b>Ley 1480 de 2011</b></span></label
      >
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      email: '',
      invalidEmail: false,
      checkTyC: false,
      mayor18: true,
    }
  },
  computed: {
    ...mapState('cartAnonimo', [
      'errorAddToCart',
      'msgErrorAddToCart',
      'dataCart',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
  },
  watch: {
    dataCart() {
      this.email = this.dataCart.cliente_correo
    },
  },
  mounted() {
    if (this.dataCart) {
      this.email = this.dataCart.cliente_correo
    }
  },
  methods: {
    ...mapActions('cartAnonimo', ['updateInfoCart']),
    validateBlur() {
      if (this.validateEmail) {
        this.invalidEmail = false
      } else {
        this.invalidEmail = true
      }
    },
    async snedEmail() {
      if (this.validateEmail && this.mayor18 && this.checkTyC) {
        let data = {
          step: '1',
          cliente_correo: this.email,
        }
        await this.updateInfoCart(data).then(() => {
          if (!this.errorAddToCart) {
            if (this.dataCart.carrito_editar == 0) {
              this.$emit('maskData')
            } else {
              this.$emit('changeStep', 2)
            }
          }
        })
      } else {
        this.invalidEmail = true
      }
    },
  },
}
</script>
